import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { initVendor } from "@/config/index.js";
import { onClickPage, eventTracking } from "@/utils/parse.js";
import {
  Button,
  Form,
  Carousel,
  CarouselItem,
  Pagination,
  FormItem,
  Input,
  Checkbox,
  Loading,
  Menu,
  MenuItem,
  Submenu,
  Table,
  TableColumn,
  Tooltip,
  Dialog,
  Progress,
} from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import i18n from "./language";

Vue.config.productionTip = false;

Vue.use(Button);
Vue.use(Form);
Vue.use(Carousel);
Vue.use(CarouselItem);
Vue.use(Pagination);
Vue.use(FormItem);
Vue.use(Input);
Vue.use(Checkbox);
Vue.use(Loading);
Vue.use(Menu);
Vue.use(MenuItem);
Vue.use(Submenu);
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Tooltip);
Vue.use(Dialog);
Vue.use(Progress);

// 自定义跳转方法
Vue.prototype.$navigateTo = function (e, destination) {
  router.push(destination);
  onClickPage(e, destination);
};
Vue.prototype.$eventTracking = eventTracking;
async function init() {
  let vendorInfo = await initVendor();
  Vue.prototype.$cookieManager = window.cookieManager;

  store.commit("common/saveVendorInfo", vendorInfo);
  new Vue({
    i18n,
    router,
    store,
    render: h => h(App),
  }).$mount("#app");
}
init();
