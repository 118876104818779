<template>
  <div class="shopping-cart">
    <choose-card
      v-show="isChoosePayment"
      ref="cardsComponent"
      :source="'cart'"
      :showValue="isChoosePayment"
    ></choose-card>
    <div id="dropIn"></div>
    <div id="root"></div>
    <p class="title">通用信息</p>
    <div class="common">
      <div>
        <p>currency：</p>
        <div class="custom-select" :class="isSelect ? 'active' : ''">
          <p @click.stop="toSelect">{{ currency }}<span></span></p>
          <div class="select-options" v-show="isSelect">
            <p
              v-for="(c, i) in allCurrencys"
              :key="i"
              :class="currency == c ? 'active' : ''"
              @click="chooseCurrency(c)"
            >
              {{ c }}
            </p>
          </div>
        </div>
      </div>
      <div>
        <p>email:</p>
        <input type="text" v-model="common.email" />
      </div>
      <div>
        <p>first name:</p>
        <input type="text" v-model="common.first_name" />
      </div>
      <div>
        <p>last name:</p>
        <input type="text" v-model="common.last_name" />
      </div>
      <div>
        <p>country:</p>
        <input type="text" v-model="common.country" disabled />
      </div>
    </div>
    <p class="title">买终身信息</p>

    <p class="desc">Coupon code</p>
    <div style="display: flex">
      <input type="text" v-model="info1.coupon_code" />
      <button @click="checkCoupon(0)" :class="info1.isCheck ? 'isLoading' : ''">
        update
      </button>
    </div>
    <p :style="{ color: info1.tipType === 0 ? 'red' : 'green' }">
      {{ info1.tip }}
    </p>
    <p class="desc" style="color: #000">产品信息</p>
    <div class="pro-container">
      <div class="pro-list">
        <p>已确定产品列表:</p>
        <table>
          <thead>
            <tr>
              <th></th>
              <th>id</th>
              <th>quantity</th>
              <th>原单价</th>
              <th>操作</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(p, index) in info1.products" :key="index">
              <th>{{ index + 1 }}</th>
              <td>{{ p.id }}</td>
              <td>{{ p.quantity }}</td>
              <td>
                {{
                  info1.priceList[index]
                    ? info1.priceList[index].originPrice
                    : "-"
                }}
              </td>
              <td>
                <p class="delete-btn" @click="deleteProduct(0, index)">删除</p>
              </td>
            </tr>
            <tr>
              <th>原总价</th>
              <td colspan="2">{{ info1.oTotalPrice }}</td>
              <th>最后总价</th>
              <td colspan="1">{{ info1.fTotalPrice }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="pro-operate">
        <div>
          产品id：
          <input type="text" v-model="currentId1" />
        </div>
        <div>
          产品quantity：<input
            type="number"
            onKeypress="return (/[\d]/.test(String.fromCharCode(event.keyCode)))"
            v-model="currentQuantity1"
            @input="validateQuantity"
          />
        </div>
      </div>
      <div class="btn-container">
        <button @click="addProduct(0)">确定添加</button>
      </div>
    </div>
    <button id="buyIt" @click="choosePayment(0)" class="mb50">买终身</button>
    <p class="title">买订阅信息</p>
    <p class="desc">Coupon code</p>
    <div style="display: flex">
      <input type="text" v-model="info2.coupon_code" />
      <button @click="checkCoupon(1)" :class="info2.isCheck ? 'isLoading' : ''">
        update
      </button>
    </div>
    <p :style="{ color: info2.tipType === 0 ? 'red' : 'green' }">
      {{ info2.tip }}
    </p>
    <p class="desc" style="color: #000">产品信息</p>
    <div class="pro-container">
      <div class="pro-list">
        <p>已确定产品列表:</p>
        <table>
          <thead>
            <tr>
              <th></th>
              <th>id</th>
              <th>quantity</th>
              <th>原单价</th>
              <th>操作</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(p, index) in info2.products" :key="index">
              <th>{{ index + 1 }}</th>
              <td>{{ p.id }}</td>
              <td>{{ p.quantity }}</td>
              <td>
                {{
                  info2.priceList[index]
                    ? info2.priceList[index].originPrice
                    : "-"
                }}
              </td>
              <td>
                <p class="delete-btn" @click="deleteProduct(1, index)">删除</p>
              </td>
            </tr>
            <tr>
              <th>原总价</th>
              <td colspan="2">{{ info2.oTotalPrice }}</td>
              <th>最后总价</th>
              <td colspan="1">{{ info2.fTotalPrice }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="pro-operate">
        <div>
          产品id：
          <input type="text" v-model="currentId2" />
        </div>
        <div>
          产品quantity：<input
            type="number"
            onKeypress="return (/[\d]/.test(String.fromCharCode(event.keyCode)))"
            v-model="currentQuantity2"
            @input="validateQuantity"
          />
        </div>
      </div>
      <div class="btn-container">
        <button @click="addProduct(1)">确定添加</button>
      </div>
    </div>
    <button id="orderIt" @click="choosePayment(1)" class="mb50">买订阅</button>
  </div>
</template>

<script>
import $ from "jquery";
import { mapGetters } from "vuex";
import chooseCard from "@/components/chooseCardComponent.vue";
import { getCurrencys } from "@/api/cart.js";

export default {
  name: "shoppingCart",
  components: { chooseCard },
  props: {
    userInfo: {
      type: Object,
      require: true,
    },
  },
  data() {
    return {
      cardNumber: null,
      Airwallex: null,
      info1: {
        coupon_code: "", // "WOSIDV60"
        products: [],
        oTotalPrice: 0,
        fTotalPrice: 0,
        priceList: [],
        tip: "",
        tipType: 0,
        isCheck: false,
      },
      info2: {
        coupon_code: "", // "WOSIREDSH"
        products: [],
        oTotalPrice: 0,
        fTotalPrice: 0,
        priceList: [],
        tip: "",
        tipType: 0,
        isCheck: false,
      },
      currency: "USD",
      currentOrderId: "",
      intId: "",
      isSelect: false,
      allCurrencys: ["USD", "JPY", "EUR"],
      currentId1: "",
      currentId2: "",
      currentQuantity1: "",
      currentQuantity2: "",

      isChoosePayment: false,
      isSub: null,
      common: {
        email: "",
        first_name: "",
        last_name: "",
        country: "US",
      },
      payssionId: null,
    };
  },
  computed: {
    ...mapGetters(["isPro", "vendorInfo"]),
  },
  methods: {
    choosePayment(n) {
      const targetInfo = n == 0 ? this.info1 : this.info2;
      if (!this.checkInfo()) {
        return;
      } else if (targetInfo.products.length == 0) {
        alert("购买产品不能为空");
        return;
      }

      this.isChoosePayment = true;
      this.isSub = n;
    },
    confirmPayWay() {
      const targetInfo = this.isSub == 0 ? this.info1 : this.info2;
      const that = this;
      const cardsComponent = that.$refs.cardsComponent;
      $.ajax({
        url: `${window.reqUrl}/api/payment/create`,
        data: {
          currency: this.currency,
          payment_method:
            cardsComponent.payMethod == 2 ? "payssion" : "airwallex",
          payment_method_type:
            cardsComponent.payMethod == 2 ? this.payssionId : "",
          buy_type: 0, //购买类型：0正常购买,1增加订阅（1升级,2降级）,3试用(不用支付，既表示未订阅)
          coupon_code: targetInfo.coupon_code,
          products: targetInfo.products,
          sub_type: this.isSub == 0 ? 2 : 1, //终身2  订阅1
          vendor: this.vendorInfo.vendor,
          ...this.common,
        },
        dataType: "json",
        type: "post",
        success: function (res) {
          cardsComponent.cartBeingProcessed = false;
          if (cardsComponent.payMethod == 2) {
            window.location.href = res.data.action.redirect_to_url.url;
            return;
          }
          that.currentOrderId = res.data.order_no;
          that.intId = res.data.id;
          if (that.isChoosePayment && cardsComponent.payMethod == 1) {
            cardsComponent.googlePay(res.data, that.isSub == 0);
            return;
          }
          if (res.data.is_pay == 0) {
            alert("success");
            return false;
          }
          cardsComponent.cartCreateOrder(that.isSub == 0 ? 0 : 1, res);
        },
        error: function (xhr) {
          if (that.isChoosePayment && cardsComponent.payMethod == 1) {
            cardsComponent.beingProcessed = false;
          }
          cardsComponent.cartBeingProcessed = false;
          const err = xhr.responseJSON;
          alert(
            this.isPro
              ? this.$t("common.error")
              : err.response
              ? err.response.data.message
              : err.message
          );
        },
      });
    },
    addProduct(n) {
      const target = n == 0 ? this.info1 : this.info2;
      target.products.push({
        id: n == 0 ? this.currentId1 : this.currentId2,
        quantity: n == 0 ? this.currentQuantity1 : this.currentQuantity2,
      });
      this.currentId1 = "";
      this.currentId2 = "";
      this.currentQuantity1 = "";
      this.currentQuantity2 = "";
      this.getPrice(n);
    },
    deleteProduct(n, i) {
      const target = n == 0 ? this.info1 : this.info2;
      target.products.splice(i, 1);
      this.getPrice(n);
    },
    buySuccess() {
      sessionStorage.setItem("order_id", this.currentOrderId);
      sessionStorage.setItem("int_id", this.intId);
      this.$navigateTo({ text: "购买成功" }, "/order-complete");
    },
    validateQuantity() {
      this.currentQuantity == 0 ? (this.currentQuantity = 1) : "";
    },
    checkInfo() {
      let res = false;
      if (this.common.email === "") {
        alert(this.$t("dm_login.enter_email"));
      } else if (!/@.*\./.test(this.common.email)) {
        alert(this.$t("login.email_format_error"));
      } else if (this.common.last_name == "" || this.common.first_name == "") {
        alert("请填写名字");
      } else {
        res = true;
      }
      return res;
    },
    chooseCurrency(c) {
      this.currency = c;
      this.isSelect = false;
      this.getPrice(0);
      this.getPrice(1);
    },
    toSelect() {
      this.isSelect = !this.isSelect;
      if (this.isSelect) {
        document.addEventListener("click", this.closeSelect);
      }
    },
    closeSelect() {
      this.isSelect = false;
      document.removeEventListener("click", this.closeSelect);
    },
    checkCoupon(n) {
      const target = n == 0 ? this.info1 : this.info2;
      target.isCheck = true;
      $.ajax({
        url: `${window.reqUrl}/api/coupon/check_code`,
        data: {
          currency: this.currency,
          payment_method: "airwallex",
          coupon: target.coupon_code,
          products: target.products,
          vendor: this.vendorInfo.vendor,
        },
        dataType: "json",
        type: "post",
        success: function (res) {
          target.isCheck = false;
          target.tip = "已更新价格";
          target.tipType = 1;
          target.oTotalPrice = res.data.format_origin_price;
          target.fTotalPrice = res.data.format_final_price;
        },
        error: function (xhr) {
          target.isCheck = false;
          const err = xhr.responseJSON;
          target.tipType = 0;
          target.tip = this.isPro
            ? this.$t("common.error")
            : err.response
            ? err.response.data.message
            : err.message;
        },
      });
    },
    getPrice(n) {
      const that = this;
      const target = n == 0 ? this.info1 : this.info2;
      if (target.products.length == 0) {
        target.oTotalPrice = target.fTotalPrice = 0;
        return;
      }
      $.ajax({
        url: `${window.reqUrl}/api/plan/get_price`,
        data: {
          currency: this.currency,
          products: target.products,
          vendor: this.vendorInfo.vendor,
        },
        dataType: "json",
        type: "post",
        success: function (res) {
          target.oTotalPrice = target.fTotalPrice =
            res.data.format_origin_price;
          if (target.coupon_code) {
            that.checkCoupon(n);
          }
          target.priceList = [];
          target.products.forEach(p => {
            const obj = res.data.products[p.id];
            target.priceList.push({
              originPrice: obj.format_original_price,
            });
          });
        },
        error: function (xhr) {
          const err = xhr.responseJSON;
          alert(
            this.isPro
              ? this.$t("common.error")
              : err.response
              ? err.response.data.message
              : err.message
          );
        },
      });
    },
  },
  created() {
    getCurrencys()
      .then(res => {
        this.allCurrencys = res.data.cur_lists;
      })
      .catch();
    if (this.userInfo) {
      this.common.email = this.userInfo.email;
      this.common.last_name = this.userInfo.last_name;
      this.common.first_name = this.userInfo.first_name;
    }
  },
  watch: {},
};
</script>

<style scoped lang="scss">
@import "@/styles/common";

.title {
  margin: 20px 0;
}

.desc {
  font-size: 14px;
  color: #ccc;
}

.mb50 {
  margin-bottom: 50px;
  margin-left: 0;
  width: 180px;
  border-radius: 5px;
}

.pro-container {
  background: #f2f2f2;
  padding: 20px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;

  .pro-list {
    background: #fff;
    color: #000;
    padding: 30px;
    border-radius: 10px;
    margin-bottom: 30px;

    @include columnGap(20px);

    table {
      border-collapse: collapse;
      border-spacing: 0;
      width: 100%;
    }

    table,
    th,
    td {
      padding: 5px;
      border: 1px solid black;
    }

    .delete-btn {
      text-decoration: underline;
      color: #6086e8;
      cursor: pointer;
    }
  }
}

.pro-operate {
  display: flex;

  @include rowGap(20px);
}

button {
  width: 120px;
  height: 40px;
  margin: 10px;
}

.btn-container {
  display: flex;
  justify-content: flex-end;

  button {
    background: #fff;
  }
}

.disabled {
  color: #ccc;
  cursor: no-drop;
}

.common {
  margin: 30px 0;
  align-items: center;
  background: #6086e826;
  padding: 20px;
  border-radius: 10px;
}

.common > div {
  display: flex;
  align-items: center;
}

.common > div > p {
  width: 90px;
  flex-shrink: 0;
}

button.isLoading,
#buyIt.isLoading,
#orderIt.isLoading {
  position: relative;
  font-size: 0;

  &::before {
    content: "";
    position: absolute;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 2px solid #6086e8;
    border-top-color: transparent;
    animation: circle infinite 0.75s linear;
    left: 45%;
    top: 18%;
  }
}

@keyframes circle {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>
