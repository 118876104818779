export const validateUsername = (rule, value, callback) => {
  value = value.trim();
  if (value === "") {
    callback(new Error(JSON.stringify({ t: "dm_login.enter_username" })));
  } else if (value.length > 50) {
    callback(new Error(JSON.stringify({ t: "register.username_limit" })));
  } else {
    callback();
  }
};

export const validateEmail = (rule, val, callback) => {
  let value = typeof val === "string" ? val : val.value;
  value = value.trim();
  if (value === "") {
    callback(new Error(JSON.stringify({ t: "dm_login.enter_email" })));
  } else if (!/@.*\./.test(value)) {
    callback(new Error(JSON.stringify({ t: "login.email_format_error" })));
  } else {
    callback();
  }
};

export const validatePassword = (rule, value, callback) => {
  value = value.trim();
  if (value === "") {
    callback(new Error(JSON.stringify({ t: "dm_login.enter_pwd" })));
  } else if (!/^.*(?=.{8,20})(?=.*\d)(?=.*[A-Za-z]{1,}).*$/.test(value)) {
    callback(new Error(JSON.stringify({ t: "register.pwd_limit" })));
  } else {
    callback();
  }
};

export const validatePassword2 = (rule, val, callback) => {
  let value = typeof val === "string" ? val : val.value;
  value = value.trim();
  if (value === "") {
    callback(new Error(JSON.stringify({ t: "dm_login.enter_pwd" })));
  } else if (!/^(?=.*[a-zA-Z])(?=.*\d)[a-zA-Z\d]{8,20}$/.test(value)) {
    callback(new Error(JSON.stringify({ t: "register.pwd_limit" })));
  } else {
    callback();
  }
};

export const validateLoginPwd = (rule, val, callback) => {
  let value = typeof val === "string" ? val : val.value;
  value = value.trim();
  if (value === "") {
    callback(new Error(JSON.stringify({ t: "dm_login.enter_pwd" })));
  } else if (!/^.{6,20}$/.test(value)) {
    callback(new Error(JSON.stringify({ t: "register.pwd_limit" })));
  } else {
    callback();
  }
};

export const validateCaptcha = (rule, { value }, callback) => {
  value = value.trim();
  if (value === "") {
    callback(new Error(JSON.stringify({ t: "dm_login.captcha_pl" })));
  } else if (!/^.{4}$/.test(value)) {
    callback(new Error(JSON.stringify({ t: "dm_login.captcha_limit" })));
  } else {
    callback();
  }
};

export function isJSONString(str) {
  try {
    JSON.parse(str);
    return true;
  } catch (error) {
    return false;
  }
}
