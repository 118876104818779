export default {
  choose_lang: "繁體中文",
  common: {
    email: "電子郵件",
    pwd: "密碼",
    send: "傳送",
    format_error: "格式錯誤！",
    error: "錯誤",
    now: "(現在)",
    vip_system: "會員系統",
    first_name: "名",
    last_name: "姓",
    tip_first_name: "名字",
    tip_last_name: "姓氏",
  },
  login: {
    login: "登入",
    title: "歡迎回來！",
    login_tip: "請登入您的帳戶",
    save_pwd: "記住我",
    free_login: "15天内免登录",
    forgot_pwd: "忘記密碼",
    no_account: "沒有帳戶嗎？",
    create_account: "立即建立帳戶",
    email_format_error: "電子郵件格式錯誤！",
    pwd_error: "密碼錯誤！",
    account_no_exist: "帳戶不存在！",
    login_success: "登入成功",
  },
  register: {
    new_account: "建立新帳戶",
    reg_tip: "請填寫表單以建立新帳戶",
    confirm_pwd: "確認密碼",
    reg_account: "建立帳戶",
    terms_and_policy:
      "建立帳戶，即表示您已閱讀並接受我們的<a class='term'>{term}</a>以及<a class='policy'>{policy}</a>。",
    term: "使用條款",
    policy: "隱私權政策",
    have_account: "已經有帳戶了嗎？",
    login: "前往登入",
    name_limit: "{fieldName}不得超過 {max} 個字！",
    email_invalid: "電子郵件地址無效",
    email_no_exist: "電子郵件地址不存在",
    pwd_limit:
      "密碼長度為8-20個字符，不包含特殊符號，需包含至少1個數字和1個字母（大小寫不限）。",
    pwd_different: "確認密碼與密碼不一致！",
    reg_fail_server: "無法建立帳戶！伺服器異常修復中請稍後再試",
    reg_fail_net: "無法建立帳戶！請檢查網路問題後重新提交",
    reg_success: "已成功建立帳戶",
    check_tip: "我接受條款和條件。",
    please_accept: "請勾選以接受條款和條件。",
    recover_pwd: "恢復密碼",
    reset: "重置",
    account_presence: "該郵箱已存在，請登錄！",
    receive_newsletter: "訂閱並接收我們的通訊。",
  },
  forget: {
    title: "重置密碼",
    tip: "請輸入您的電子郵件地址，我們將向您傳送關於如何重置密碼的說明資料。",
    backToLogin: "返回<a class='loginLink'>{login}</a>",
    login: "登入",
    forget_success: "密碼重置郵件已傳送至電子郵箱！",
    send_fail_server: "傳送失敗！伺服器異常修復中請稍後再試",
    send_fail_net: "傳送失敗！請檢查網路問題後重新提交",
  },
  update: {
    title: "修改密碼",
    tip: "請輸入您的新密碼。",
    new_pwd: "新密碼",
    submit: "提交",
    update_fail_server: "修改失敗！伺服器異常修復中請稍後再試",
    update_fail_net: "修改失敗！請檢查網路問題後重新提交",
    update_success: "修改成功",
  },
  psersonal: {
    sidebarItems: [
      "個人資訊",
      "訂閱",
      "設備管理",
      "結算",
      "聯繫支持",
      "加值服務",
      "子帳號功能",
      "修改密碼",
      "購物車",
      "Spotify Music Converter",
    ],
  },
  userInfoModule: {
    edit: "編輯",
    name: "名字",
    update: "更新",
    reg_time: "註冊時間",
    updatePwd: {
      forgot: "忘記",
      current_pwd: "当前密碼",
      current_pwd_p: "输入当前密碼",
      new_pwd_p: "輸入新密碼",
      confirm_pwd_p: "重复新密碼",
      update_btn: "更新密碼",
    },
    update_success: "名字修改成功",
    pwd_update_success: "密碼修改成功，請重新登錄",
    profile_update_success: "頭像更改成功",
    destroy: "註銷帳號",
    destroy_tip: "你確認要註銷帳號嗎？",
    destroy_tip2:
      "此操作將永久刪除您的帳戶和相關數據，您帳號下啟用的功能將在注銷成功後不可用，請謹慎操作！",
    destroy_success: "註銷帳號成功!",
    pwd_wrong: "使用者密碼錯誤！",
    have_sub: "您有正常續費狀態的訂閱不能注銷,請先暫停訂閱!",
  },
  subscription: {
    stop_text: "您已暫停 {plan} 的會員訂閱計劃。",
    cancel_text: "您已取消{plan}的會員訂閱計劃。",
    canceling: "您的{plan}會員訂閱計劃正在取消中。",
    renew: "恢復訂閱",
    renew_sucess: "恢復訂閱成功！",
    expire_date: "到期日",
    status_text: "訂閱狀態",
    leave: "離開",
    purchase_date: "購買時間",
    end_date: "下次付款",
    price: "價格",
    num: "支持設備數",
    account_status: "帳戶狀態",
    no_subscription: "您目前暫無訂閱,",
    recommend: "或許您會對以下內容感興趣",
    learn_more: "了解更多",
    free_download: "免費下載",
    trial_limit: "試用限制",
    blocked_on: "阻止於",
    trial_start: "試用開始時間",
    start_subscription: "開始訂閱",
    is_trial: "您目前處於試用狀態",
    manage_btn: "管理訂閱",
    change_payment: "更改支付詳情",
    payment_detail: "支付詳情",
    leave_tip: "您確認需要離開家庭套餐嗎？",
    leave_success: "您已成功離開家庭套餐！",
    singular_device_num: "{type}设备有{num1}台可使用，已激活{num2}台",
    plural_device_num: "{type}设备有{num1}台可使用，已激活{num2}台",
    see_detail: "查看詳情",
    active: "活躍",
    pause: "暫停",
    overdue: "(已逾期)",
    update_payment_success: "更改付款方式成功",
    being_processed: "處理中",
  },
  subaccount: {
    invite: "邀請人加入",
    send_invite: "發送邀請",
    manage_title: "管理家庭成員",
    remove: "移除",
    send_success: "您已成功發送邀請郵件，待對方確認！",
    remove_user: "您確認需要移除{name}這位使用者嗎？",
    all_product: "所有產品",
  },
  billing: {
    excl_tax: "（不含銷售稅）",
    view_receipt: "查看收據",
    all_time: "所有時間",
    refunded: "已退款",
  },
  manage_devices: {
    reset_all_code: "重置所有設備",
    reset_a_code: "重置當前設備",
    all_reset_success: "您已成功重置所有設備！",
    a_reset_success: "您已成功重置{name}！",
    num_tip: "本年度僅剩{num}次重置機會。",
    a_warning_text: "您確認要重置{name}嗎？",
    all_warning_text: "您確認需要重置所有設備嗎？",
    device_name: "設備名字",
    system: "系統",
    reg_time: "註冊時間",
    using_device: "您正在使用{num2}台設備中的{num1}台",
    all: "所有",
    residue_num: "剩餘重置次數",
    reset_history: "重置歷史時間",
    reset_fail_9: "重置失敗，沒有符合的數據可以重置",
    reset_fail_10:
      "重置失敗，超過次數限制。如有疑问，请前往Contact Support联系客服。",
  },
  contact: {
    support_center: "支持中心",
    support_tip: "在這裡找到您需要的信息",
    feedback: "回饋意見",
    submit: "提交",
    feedback_fail: "無法提交回饋，請稍後再試！",
    feedback_success: "感謝您的回饋！",
    enter_content: "請輸入回饋內容",
    content_placeholder: "請在此留下您的建議或問題。",
    select: "選擇",
    select_software: "請選擇軟體",
  },
  dm_home: {
    logout: "登出",
  },
  dm_login: {
    login_desc: "歡迎回來，請登錄您的帳戶。",
    captcha_pl: "請輸入驗證碼",
    or: "或者",
    reg: "註冊",
    enter_tip: "請輸入您的{fieldName}",
    enter_email: "請輸入電子郵件",
    enter_pwd: "請輸入密碼",
    captcha_limit: "驗證碼長度為4",
    login_expired: "为了保护您的账户安全，您需要重新输入密码。",
    account_pwd_err: "登入帳號或密碼錯誤！",
    join_free_download: "立馬加入 {name} 會員，<span>享無限音樂下載</span>",
    new_users_only: "僅限新用戶",
    bset_solution: "最好用的串流音樂/影片下載幫手",
  },
  dm_reg: {
    create: "建立帳戶",
    fill_form: "請填寫下面的表格以創建新帳戶。",
  },
  dm_reset_pwd: {
    reset: "恢復您的密碼",
    reset_pwd: "重設密碼",
    reset_tip: "請輸入您的電子郵件地址，我們將向您發送有關如何重設密碼的說明。",
    back_login: "返回登錄",
    email_error: "郵箱不存在",
    reset_success: "您已成功重置密碼",
    email_had_sent: "重設密碼的電子郵件已發送到郵箱，請重設您的密碼！",
    no_sign_up: "此電子郵件地址尚無帳戶，請註冊一個帳戶。",
  },
  manageSubscription: {
    cancel_sub: "暫停訂閱",
    tip: "如果您現在暂停訂閱，您仍然可以在本計費週期結束之前使用{name}。",
    cancel_success: "暫停訂閱成功。",
    cancel_text:
      "您已成功暫停 {productName} 的會員訂閱計劃。但您仍可繼續使用 {productName} 直到您的訂閱會員最後一天。",
    are_you_sure: "您確認要暫停訂閱嗎？",
    request_success: `您的請求已成功發送，我們的客服中心將在48小時內處理您的請求，請注意查收來自{officialEmail}的郵件。`,
    title: "管理訂閱",
    desc: "您目前的套餐為：",
    change_methods: "更換訂閱方式",
    choose_system_tip: "請選擇你所需要的設備系統",
    choose_num_tip: "請選擇你所需的設備數",
    enter_num: "请输入您所需要的设备数",
    click_enter: "點選輸入",
    confirm: "確認",
    cancel: "取消",
    choose_card_tip: "請選擇支付卡號",
    add_card: "新增卡號",
    choose_way_tip: "請選擇你所需要的訂閱方式",
    discounts_tip: "已經為您優惠了",
    total: "總計：",
    excl_tax: "（不含銷售稅）",
    be_counting: "正在計算價格",
    end_tip: "您的到期日為：",
    thank_text: "再次衷心感謝您的持續支持，期待您的下次光臨！",
    back: "返回訂閱",
    update_success: "更改訂閱成功",
    pay_btn: "立即支付",
    monthy: "月付",
    annual: "年度",
    life_time: "終身",
    more: "更多",
    pay_tip:
      "更改支付方式：平台將會扣取新卡0.01，扣取成功即可綁定，後續將原路退回。",
    is_add: "新增卡",
    is_set: "設定新卡",
  },
  receipt: {
    title: "{name} 的收據",
    back: "返回",
    receipt_text: "收據",
    amount_paid: "已付金額",
    date: "收據日期",
    pay_method: "付款方式",
    discount: "折扣",
    discount_tip: "列出的商品已包含在價格中",
    contact_tip: "如果您需要協助， 請聯絡我們",
    thank: "謝謝",
    item: "項目與描述",
    qty: "數量",
    price: "價格",
    amount: "總提款金額",
    subtotal: "小計",
    total: "總計",
  },
  order_complete: {
    title: "訂單完成！",
    desc: "感謝您的購買！交貨郵件將很快發送到客戶郵件。",
    order_id: "您的訂單編號",
    price: "訂單總計",
    name: "產品名稱",
    delivery: "交貨",
    electronic: "電子",
    unit_price: "單價",
    quantity: "數量",
    final: "折扣價格",
    detail: "訂單詳情",
    license_email: "授權電子郵件",
    license_tip: "授權金鑰將包含在交貨電子郵件中。",
    contact_text: "如果您未收到交貨電子郵件，請聯繫客戶支援：",
  },
  popup: {
    ok: "確定",
    cancel: "取消",
    confirm: "確認",
    options: ["軟體問題", "價格", "其他"],
  },
  add_new_card: {
    please_fill: "請完善信息",
  },
  spotify: {
    "download-spotify-to-mp3": "下載 Spotify 為 mp3 檔",
    upgrade: "升級",
    "enter-a-spotify-url-of-any-track-to-download":
      "貼入要下載的 Spotify URL 連結",
    cancel: "取消",
    search: "搜尋",
    get_started: "複製要下載的串流媒體 URL",
    please_wait: "下載中...請耐心等候",
    upgrade_now: "立馬升級，解鎖無限下載",
    oops: "糟糕！出錯了。",
    unexpected_error: "發生了意想不到的錯誤。請檢查連線後再試。",
    retry: "重試",
    have_you_made_payment: "已經支付完成？",
    click_yes_below: "請點選下方的「是」以更新您的訂閱權益。",
    no: "否",
    yes: "是",
    you_may_give_an_incorrect_url_change_a_url_or_try_again:
      "無法解析該 URL，請更改為正確的 URL 或重試。",
    max_count: "一次最多選擇 {pageSize} 首音樂",
    clear_all: "清除全部",
    total: "全部",
    selected: "已選",
    title: "名稱",
    album: "專輯",
    duration: "時長",
    get_premium: "訂閱會員",
    you_have_songs_left:
      "還剩 <span>{num}</span> 首歌曲限額。立馬升級會員，解鎖無限下載權限。",
    free_trial: "免費試用",
    premium: "高級會員",
    desktop_app: "電腦端 APP",
    limited_features: "功能有限",
    current_plan: "目前方案",
    monthly: "1 個月訂閱",
    annually: "1 年訂閱",
    lifetime: "永久買斷",
    mon: "月",
    lifelong: "终身",
    try_lt_free: "免費試用",
    audio_quality: "音質",
    normal: "一般",
    download_format: "下載格式",
    download_limit: "下載限制",
    two_songs: "2 首歌曲",
    unlimited: "無限制",
  },
  advertise: {
    desc: "35 倍速超快下載單曲、專輯、播放清單、Podcast 等資源",
    learn_more: "了解更多",
  },
  celebrate: "已成功解鎖無限下載功能，立馬前往獲取海量動聽歌曲吧！",
  toolkit: "工具",
  my_account: "我的帳戶",
};
