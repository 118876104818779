import Vue from "vue";
import VueI18n from "vue-i18n";
import locale from "element-ui/lib/locale";

import en_US from "./lang/en.js";
import ja_JP from "./lang/jp.js";
import zh_TW from "./lang/tw.js";
import fr_FR from "./lang/fr.js";
import de_DE from "./lang/de.js";
import es_ES from "./lang/es.js";
import pt_PT from "./lang/pt.js";

// dm
import it_IT from "./lang/it.js";
import ko_KR from "./lang/ko.js";
import nl_NL from "./lang/nl.js";
import pl_PL from "./lang/pl.js";

import el_en from "element-ui/lib/locale/lang/en";
import el_ja from "element-ui/lib/locale/lang/ja";
import el_tw from "element-ui/lib/locale/lang/zh-TW";
import el_fr from "element-ui/lib/locale/lang/fr";
import el_de from "element-ui/lib/locale/lang/de";
import el_es from "element-ui/lib/locale/lang/es";
import el_pt from "element-ui/lib/locale/lang/pt";
import el_it from "element-ui/lib/locale/lang/it";
import el_ko from "element-ui/lib/locale/lang/ko";
import el_nl from "element-ui/lib/locale/lang/nl";
import el_pl from "element-ui/lib/locale/lang/pl";

Vue.use(VueI18n);

const messages = {
  en_US: {
    ...en_US,
    ...el_en,
  },
  ja_JP: {
    ...ja_JP,
    ...el_ja,
  },
  zh_TW: {
    ...zh_TW,
    ...el_tw,
  },
  fr_FR: {
    ...fr_FR,
    ...el_fr,
  },
  de_DE: {
    ...de_DE,
    ...el_de,
  },
  es_ES: {
    ...es_ES,
    ...el_es,
  },
  pt_PT: {
    ...pt_PT,
    ...el_pt,
  },
  it_IT: {
    ...it_IT,
    ...el_it,
  },
  ko_KR: {
    ...ko_KR,
    ...el_ko,
  },
  nl_NL: {
    ...nl_NL,
    ...el_nl,
  },
  pl_PL: {
    ...pl_PL,
    ...el_pl,
  },
};

const dmExclusive = ["it_IT", "ko_KR", "nl_NL", "pl_PL"];
const isVh = window.top != window;
let lan = localStorage.getItem("language")
  ? localStorage.getItem("language")
  : navigator.language.replace("-", "_");
if (!(lan in messages) || (isVh && dmExclusive.includes(lan))) {
  lan = "en_US";
}
const i18n = new VueI18n({
  locale: lan,
  messages,
});
locale.i18n((key, value) => i18n.t(key, value));

export default i18n;
