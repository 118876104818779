<template>
  <div>
    <img
      loading="lazy"
      @click="changeStatus"
      :style="{ height: isvh ? '40px' : '48px', width: '24px' }"
      :src="
        isvh
          ? require(`@/assets/img/icon/${isText ? 'open' : 'close'}-eye.svg`)
          : require(`@/assets/img/icon/dm-${isText ? 'open' : 'close'}-eye.svg`)
      "
    />
  </div>
</template>

<script>
export default {
  name: "eyeComponent",
  props: {
    isText: {
      type: Boolean,
      default: false,
    },
    isvh: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    changeStatus() {
      this.$emit("changeStatus");
    },
  },
};
</script>
