export default {
  choose_lang: "Español",
  common: {
    email: "Correo electrónico",
    pwd: "Contraseña",
    send: "Enviar",
    format_error: "格式错误！",
    error: "Error",
    now: "(ahora)",
    vip_system: "Sistema de membresía",
    first_name: "Nombre",
    last_name: "Apellido",
    tip_first_name: "nombre",
    tip_last_name: "apellido",
  },
  login: {
    login: "Iniciar sesión",
    title: "Bienvenido de nuevo",
    login_tip: "Inicie sesión con su cuenta",
    save_pwd: "Recordarme",
    free_login: "Inicio de sesión automático en 15 días",
    forgot_pwd: "¿Ha olvidado su contraseña?",
    no_account: "¿Aún no tiene una cuenta?",
    create_account: "Regístrese",
    email_format_error: "El correo electronico que ingresó es incorrecto.",
    pwd_error: "¡Contraseña incorrecta!",
    account_no_exist: "Esta cuenta no existe.",
    login_success: "Inicio de sesión correcta",
  },
  register: {
    new_account: "Crear una nueva cuenta",
    reg_tip: "Rellene el formulario para crear una nueva cuenta",
    confirm_pwd: "Confirmar contraseña",
    reg_account: "Crear cuenta",
    terms_and_policy:
      "Estoy de acuerdo con las <a class='term'>{term}</a> y <a class='policy'>{policy}</a>.",
    term: "Condiciones de uso",
    policy: "Política de privacidad",
    have_account: "¿Ya tiene una cuenta? ",
    login: " Iniciar sesión",
    name_limit: "¡El {fieldName} no debe exceder los {max} caracteres!",
    email_invalid: "Correo inválido",
    email_no_exist: "El correo electrónico no existe",
    pwd_limit:
      "La contraseña debe tener entre 8 y 20 caracteres, sin símbolos especiales, y debe incluir al menos 1 número y 1 letra (mayúscula o minúscula).",
    pwd_different: "Las contraseñas no coinciden!",
    reg_fail_server:
      "No se ha podido crear la cuenta. El servidor está fuera de servicio temporalmente. Vuelva a intentarlo más tarde.",
    reg_fail_net:
      "No se ha podido crear la cuenta. Compruebe su conexión a Internet e inténtelo de nuevo.",
    reg_success: "Su cuenta se ha creado correctamente.",
    check_tip: "Acepto los términos y condiciones.",
    please_accept: "Por favor, marque para aceptar los términos y condiciones.",
    recover_pwd: "Recuperar contraseña",
    reset: "Restablecer",
    account_presence:
      "¡Esta dirección de correo electrónico ya existe, por favor inicie sesión!",
    receive_newsletter: "Suscríbete y recibe nuestro boletín.",
  },
  forget: {
    title: "Restablecer contraseña",
    tip: "Introduce su dirección de correo electrónico y le enviaremos instrucciones para restablecer la contraseña.",
    backToLogin: "Volver al<a class='loginLink'>{login}</a>",
    login: "inicio de sesión",
    forget_success:
      "Le hemos enviado un correo electrónico para que restablezca la contraseña.",
    send_fail_server:
      "No se ha podido enviar. El servidor está fuera de servicio temporalmente. Vuelva a intentarlo más tarde.",
    send_fail_net:
      "No se ha podido enviar. Compruebe su conexión a Internet e inténtelo de nuevo.",
  },
  update: {
    title: "Cambiar contraseña",
    tip: "Introduzca su nueva contraseña",
    new_pwd: "Nueva contraseña",
    submit: "Enviar",
    update_fail_server:
      "No se ha podido restablecer. El servidor está fuera de servicio temporalmente. Vuelva a intentarlo más tarde.",
    update_fail_net:
      "No se ha podido restablecer. Compruebe su conexión a Internet e inténtelo de nuevo.",
    update_success: "Se ha restablecido la contraseña correctamente",
  },
  psersonal: {
    sidebarItems: [
      "Información personal",
      "Suscripción",
      "Gestionar dispositivos",
      "Facturación",
      "Contactar con soporte",
      "Servicios de valor añadido",
      "Funcionalidad de subcuenta",
      "Cambiar contraseña",
      "Carrito de compras",
      "Spotify Music Converter",
    ],
  },
  userInfoModule: {
    edit: "editar",
    name: "Nombre",
    update: "Actualizar",
    reg_time: "Hora de registro",
    updatePwd: {
      forgot: "Olvidado",
      current_pwd: "Contraseña actual",
      current_pwd_p: "Ingrese la contraseña actual",
      new_pwd_p: "Ingrese una nueva contraseña",
      confirm_pwd_p: "Repita la nueva contraseña",
      update_btn: "Actualizar contraseña",
    },
    update_success: "Modificación del nombre exitosa",
    pwd_update_success:
      "Contraseña modificada correctamente, por favor, inicie sesión de nuevo.",
    profile_update_success: "Cambio de avatar exitoso",
    destroy: "Borrar cuenta",
    destroy_tip: "¿Estás seguro de que quieres eliminar tu cuenta?",
    destroy_tip2:
      "Esta operación eliminará permanentemente su cuenta y los datos relacionados. Las funciones activadas en su cuenta no estarán disponibles después de cerrar sesión con éxito. ¡Por favor, proceda con precaución!",
    destroy_success: "¡Cierre de sesión en la cuenta exitoso!",
    pwd_wrong: "¡Contraseña de usuario incorrecta!",
    have_sub:
      "No puede cancelar una suscripción con estado de renovación normal. ¡Por favor, pause la suscripción primero!",
  },
  subscription: {
    stop_text: "Ha pausado el plan de suscripción de miembro para {plan}.",
    cancel_text: "La suscripción de membresía de tu {plan} ha sido cancelada.",
    canceling:
      "Su plan de suscripción premium para {plan} se está cancelando actualmente.",
    renew: "Reanudar suscripción",
    renew_sucess: "¡Reanudación de suscripción exitosa!",
    expire_date: "Fecha de vencimiento",
    status_text: "Estado de la suscripción",
    leave: "Salir",
    purchase_date: "Hora de compra",
    end_date: "Próximo pago",
    price: "Precio",
    num: "Número de dispositivos compatibles",
    account_status: "Estado de la cuenta",
    no_subscription: "Actualmente no tienes suscripciones, ",
    recommend: "quizás te interese el siguiente contenido",
    learn_more: "Obtener más información",
    free_download: "Descarga gratuita",
    trial_limit: "Limitaciones de prueba",
    blocked_on: "Bloqueado en",
    trial_start: "Hora de inicio de la prueba",
    start_subscription: "Iniciar suscripción",
    is_trial: "Actualmente se encuentra en estado de prueba",
    manage_btn: "Gestionar suscripción",
    change_payment: "Cambiar detalles de pago",
    payment_detail: "Detalles de pago",
    leave_tip: "¿Estás seguro de que quieres salir del plan familiar?",
    leave_success: "¡Has salido con éxito del plan familiar!",
    singular_device_num:
      "{num1} dispositivos {type} disponibles, {num2} activado",
    plural_device_num:
      "{num1} dispositivos {type} disponibles, {num2} activados",
    see_detail: "Ver detalles",
    active: "Activo",
    pause: "Pausa",
    overdue: "(Vencido)",
    update_payment_success: "Cambio de método de pago exitoso",
    being_processed: "Procesando",
  },
  subaccount: {
    invite: "Invitar a personas a unirse",
    send_invite: "Enviar invitación",
    manage_title: "Gestionar miembros de la familia",
    remove: "Eliminar",
    send_success:
      "¡Has enviado con éxito un correo de invitación, a la espera de la confirmación de la otra parte!",
    remove_user: "¿Estás seguro de que quieres eliminar al usuario {name}?",
    all_product: "Todos los productos",
  },
  billing: {
    excl_tax: "(excl. impuesto de ventas)",
    view_receipt: "Ver recibo",
    all_time: "Todo el tiempo",
    refunded: "Reembolsado",
  },
  manage_devices: {
    reset_all_code: "Restablecer todos los dispositivos",
    reset_a_code: "Restablecer el dispositivo actual",
    all_reset_success: "¡Ha restablecido todos los dispositivos correctamente!",
    a_reset_success: "¡Ha restablecido {name} con éxito!",
    num_tip: "Solo queda {num} oportunidad de reinicio para este año.",
    a_warning_text: "¿Está seguro de que desea restablecer {name}?",
    all_warning_text:
      "¿Estás seguro de que quieres restablecer todos los dispositivos?",
    device_name: "Nombre del dispositivo",
    system: "Sistema",
    reg_time: "Hora de registro",
    using_device: "Está utilizando {num1} de {num2} dispositivos",
    all: "Todo",
    residue_num: "Intentos de restablecimiento restantes",
    reset_history: "Tiempo de historial de restablecimiento",
    reset_fail_9:
      "Restablecimiento fallido, no hay datos coincidentes para restablecer",
    reset_fail_10:
      "Reinicio fallido, excedido el límite. Si tiene alguna pregunta, póngase en contacto con soporte.",
  },
  contact: {
    support_center: "Centro de soporte",
    support_tip: "Descubre lo que necesitas aquí",
    feedback: "Comentarios",
    submit: "Enviar",
    feedback_fail:
      "No se pudo enviar el comentario, inténtelo de nuevo más tarde.",
    feedback_success: "¡Gracias por tus comentarios!",
    enter_content: "Por favor, ingrese el contenido de los comentarios",
    content_placeholder: "Por favor, deje sus sugerencias o preguntas aquí.",
    select: "Seleccionar",
    select_software: "Por favor seleccione el software",
  },
  dm_home: {
    logout: "Cerrar sesión",
  },
  dm_login: {
    login_desc: "Bienvenido de nuevo, por favor inicie sesión en su cuenta.",
    captcha_pl: "Por favor, ingrese el código de verificación",
    or: "o",
    reg: "Registrarse",
    enter_tip: "Por favor, ingrese su {fieldName}.",
    enter_email: "Por favor, ingrese su correo electrónico",
    enter_pwd: "Por favor, ingrese su contraseña",
    captcha_limit: "La longitud del código de verificación es de 4",
    login_expired:
      "Para proteger la seguridad de su cuenta, debe volver a ingresar su contraseña.",
    account_pwd_err: "¡Error de cuenta de inicio de sesión o de contraseña!",
    join_free_download:
      "Únase a {name} hoy y desbloquee <span>descargas gratuitas</span>",
    new_users_only: "Solo para nuevos usuarios",
    bset_solution:
      "Las mejores soluciones para descargar vídeos y música en streaming",
  },
  dm_reg: {
    create: "Crear cuenta",
    fill_form:
      "Complete el formulario a continuación para crear una nueva cuenta.",
  },
  dm_reset_pwd: {
    reset: "Recuperar su contraseña",
    reset_pwd: "Restablecer contraseña",
    reset_tip:
      "Por favor, ingrese su dirección de correo electrónico y le enviaremos instrucciones sobre cómo restablecer su contraseña.",
    back_login: "Volver a iniciar sesión",
    email_error: "El correo electrónico no existe",
    reset_success: "Ha restablecido su contraseña correctamente.",
    email_had_sent:
      "Se ha enviado el email de restablecimiento de contraseña al buzón, ¡por favor restablezca su contraseña!",
    no_sign_up:
      "No hay una cuenta para esta dirección de correo electrónico, por favor regístrese para obtener una cuenta. ",
  },
  manageSubscription: {
    title: "Gestionar suscripción",
    desc: "Su plan actual es:",
    change_methods: "Cambiar método de suscripción",
    choose_system_tip:
      "Por favor, seleccione el sistema de dispositivo que necesita",
    choose_num_tip:
      "Por favor, seleccione la cantidad de dispositivos que necesita",
    enter_num: "Por favor, ingrese la cantidad de dispositivos que necesita",
    click_enter: "Haga clic para ingresar",
    confirm: "Confirmar",
    cancel: "Cancelar",
    choose_card_tip: "Por favor, seleccione el número de tarjeta de pago",
    add_card: "Agregar número de tarjeta",
    choose_way_tip:
      "Por favor, seleccione el método de suscripción que necesita",
    discounts_tip: " Se le ha aplicado un descuento",
    total: "Total:",
    excl_tax: "(excl. impuesto de ventas)",
    be_counting: "Calculando el precio",
    end_tip: "Su fecha de vencimiento es:",
    thank_text:
      "Una vez más, gracias de corazón por su continuo apoyo. ¡Esperamos su próxima visita!",
    back: "Volver a la suscripción",
    update_success: "Cambio de suscripción exitoso",
    pay_btn: "Pagar ahora",
    monthy: "Mensual",
    annual: "Anual",
    life_time: "De por vida",
    more: "Más",
    pay_tip:
      "Cambiar el método de pago: La plataforma deducirá 0.01 de la nueva tarjeta, y tras la deducción exitosa, se podrá vincular. Posteriormente, el importe se reembolsará al método de pago original.",
    is_add: "Añadir nueva tarjeta",
    is_set: "Configurar nueva tarjeta",
    cancel_sub: "Pausar suscripción",
    tip: "Si pausa su suscripción ahora, aún puede utilizar {name} hasta el final del ciclo de facturación actual.",
    cancel_success: "Pausa de la suscripción exitosa.",
    cancel_text:
      "Ha pausado con éxito el plan de suscripción de miembro para {productName}. Sin embargo, aún puede seguir utilizando {productName} hasta el último día de su suscripción.",
    are_you_sure: "¿Está seguro/a de que desea pausar la suscripción?",
    request_success: `Su solicitud se ha enviado correctamente; nuestro centro de atención al cliente la procesará en un plazo de 48 horas. Por favor, revise su correo electrónico de {officialEmail}.`,
  },
  receipt: {
    title: "Recibo de {name}",
    back: "Volver",
    receipt_text: "Recibo",
    amount_paid: "Monto pagado",
    date: "Fecha del recibo",
    pay_method: "Método de pago",
    discount: "Descuento",
    discount_tip: "Los artículos enumerados están incluidos en el precio",
    contact_tip: "Si necesita ayuda con {brand}, por favor contáctenos",
    thank: "Gracias",
    item: "Artículos y Descripción",
    qty: "Cantidad",
    price: "Precio",
    amount: "Monto Bruto Retirado",
    subtotal: "Subtotal",
    total: "TOTAL",
  },
  order_complete: {
    title: "¡Pedido completado!",
    desc: "¡Gracias por su compra! Pronto se enviará un correo electrónico de entrega al correo electrónico del cliente.",
    order_id: "Su ID de pedido",
    price: "Total del pedido",
    name: "Nombre del producto",
    delivery: "Entrega",
    electronic: "Electrónico",
    unit_price: "Precio unitario",
    quantity: "Cantidad",
    final: "Precio con Descuento",
    detail: "Detalles del pedido",
    license_email: "Correo electrónico con licencia",
    license_tip:
      "La clave de licencia se incluirá en el correo electrónico de entrega.",
    contact_text:
      "Si no recibió el correo electrónico de entrega, por favor contacte con el soporte al cliente:",
  },
  popup: {
    ok: "Ok",
    cancel: "Cancelar",
    confirm: "Confirmar",
    options: ["Problemas de software", "Precio", "Otros"],
  },
  add_new_card: {
    please_fill: "Por favor complete la información",
  },
  spotify: {
    "download-spotify-to-mp3": "Descargar Spotify a mp3",
    upgrade: "Mejora",
    "enter-a-spotify-url-of-any-track-to-download":
      "Ingresa la URL de Spotify de cualquier pista para descargarla",
    cancel: "Cancelar",
    search: "Buscar",
    get_started: "Copia una URL para comenzar",
    please_wait: "Por favor espere mientras se descarga su archivo",
    upgrade_now: "Actualice ahora para descargas ilimitadas",
    oops: "¡Ups! Algo salió mal.",
    unexpected_error:
      "Se produjo un error inesperado. Verifique su conexión y vuelva a intentarlo.",
    retry: "Rever",
    have_you_made_payment: "¿Has realizado el pago?",
    click_yes_below:
      "Haga clic en «Sí» a continuación para actualizar los beneficios de su suscripción.",
    no: "No",
    yes: "Sí",
    you_may_give_an_incorrect_url_change_a_url_or_try_again:
      "Es posible que hayas proporcionado una URL incorrecta. Cambia la URL o inténtalo de nuevo.",
    max_count: "Máximo {pageSize} canciones a la vez",
    clear_all: "Limpiar todo",
    total: "Total",
    selected: "Seleccionado",
    title: "Título",
    album: "Álbum",
    duration: "Duración",
    get_premium: "Obtener Premium",
    you_have_songs_left:
      "Te quedan <span>{num}</span> canciones. Actualiza ahora para obtener descargas ilimitadas.",
    free_trial: "Prueba gratuita",
    premium: "De primera calidad",
    desktop_app: "Aplicación de escritorio",
    limited_features: "Funciones limitadas",
    current_plan: "Plan actual",
    monthly: "Pagar mensualmente",
    annually: "Pagar anualmente",
    lifetime: "Pagar una vez",
    mon: "mes",
    lifelong: "vida",
    try_lt_free: "Pruébalo gratis",
    audio_quality: "Calidad de audio",
    normal: "Normal",
    download_format: "Formato de descarga",
    download_limit: "Límite de descarga",
    two_songs: "2 canciones",
    unlimited: "Ilimitado",
  },
  advertise: {
    desc: "Descarga canciones, álbumes, listas de reproducción y podcasts a una velocidad 35 veces más rápida.",
    learn_more: "Aprende más",
  },
  celebrate:
    "Ya tienes todo listo para las descargas gratuitas. ¡Comienza ahora mismo!",
  toolkit: "Kit de herramientas",
  my_account: "Mi cuenta",
};
