export default {
  choose_lang: "Português",
  common: {
    email: "Email",
    pwd: "Senha",
    send: "Enviar",
    format_error: "格式错误！",
    error: "Erro",
    now: "(agora)",
    vip_system: "Sistema de associação",
    first_name: "Nome",
    last_name: "Sobrenome",
    tip_first_name: "nome",
    tip_last_name: "sobrenome",
  },
  login: {
    login: "Faça login",
    title: "Bem-vindo de volta",
    login_tip: "Faça login na sua conta",
    save_pwd: "Lembre-se da senha",
    free_login: "Login automático em 15 dias",
    forgot_pwd: "Esqueceu sua senha?",
    no_account: "Ainda não tem uma conta?",
    create_account: "Crie uma!",
    email_format_error: "Erro de formato de email!",
    pwd_error: "Senha incorreta!",
    account_no_exist: "A conta não existe!",
    login_success: "Login bem-sucedido",
  },
  register: {
    new_account: "Criar uma nova conta",
    reg_tip: "Preencha o formulário abaixo para criar uma nova conta",
    confirm_pwd: "Confirmar senha",
    reg_account: "Criar conta",
    terms_and_policy:
      "Eu li e concordo com os <a class='term'>{term}</a> e <a class='policy'>{policy}</a>.",
    term: "Termos de Serviço",
    policy: "Política de Privacidade",
    have_account: "Já tem uma conta? ",
    login: "Faça login.",
    name_limit: "O {fieldName} não pode exceder {max} caracteres!",
    email_invalid: "Email inválido",
    email_no_exist: "O endereço de email não existe",
    pwd_limit:
      "A senha deve ter de 8 a 20 caracteres, sem símbolos especiais, e deve incluir pelo menos 1 número e 1 letra (maiúscula ou minúscula).",
    pwd_different: "A conmfirmação de senha não confere!",
    reg_fail_server:
      "A criação falhou! Erro no Servidor reparando, tente novamente mais tarde.",
    reg_fail_net: "A criação falhou! Verifique a conexão de rede e reenvie.",
    reg_success: "Criado com sucesso",
    check_tip: "Eu aceito os termos e condições.",
    please_accept: "Por favor, marque para aceitar os termos e condições.",
    recover_pwd: "Recuperar senha",
    reset: "Reiniciar",
    account_presence:
      "Este endereço de e-mail já existe, por favor faça login!",
    receive_newsletter: "Subscreva e receba a nossa newsletter.",
  },
  forget: {
    title: "Recuperar senha",
    tip: "Digite seu endereço de email e enviaremos instruções sobre como redefinir sua senha.",
    backToLogin: "Retornar ao<a class='loginLink'>{login}</a>",
    login: "login",
    forget_success:
      "O email de redefinição de senha foi enviado para seu endereço de email!",
    send_fail_server:
      "Falha ao enviar! Erro no Servidor reparando, tente novamente mais tarde.",
    send_fail_net: "Falha ao enviar! Verifique a conexão de rede e reenvie.",
  },
  update: {
    title: "Alterar senha",
    tip: "Digite sua nova senha",
    new_pwd: "Nova senha",
    submit: "Enviar",
    update_fail_server:
      "Falha na redefinição de senha! Erro no Servidor reparando, tente novamente mais tarde.",
    update_fail_net:
      "Falha na redefinição de senha! Erro no Servidor reparando, tente novamente mais tarde.",
    update_success: "Se ha restablecido la contraseña correctamente",
  },
  psersonal: {
    sidebarItems: [
      "Informações pessoais",
      "Assinatura",
      "Gerenciar dispositivos",
      "Faturamento",
      "Contatar o suporte",
      "Serviços de valor agregado",
      "Funcionalidade de subconta",
      "Alterar senha",
      "Carrinho de compras",
      "Spotify Music Converter",
    ],
  },
  userInfoModule: {
    edit: "editar",
    name: "Nome",
    update: "Atualizar",
    reg_time: "Hora do registro",
    updatePwd: {
      forgot: "Esqueceu",
      current_pwd: "Senha atual",
      current_pwd_p: "Digite a senha atual",
      new_pwd_p: "Digite a nova senha",
      confirm_pwd_p: "Repita a nova senha",
      update_btn: "Atualizar senha",
    },
    update_success: "Nome modificado com sucesso",
    pwd_update_success:
      "Senha alterada com sucesso, por favor, faça login novamente.",
    profile_update_success: "Avatar alterado com sucesso",
    destroy: "Deletar conta",
    destroy_tip: "Tem certeza de que deseja excluir sua conta?",
    destroy_tip2:
      "Esta operação excluirá permanentemente sua conta e dados relacionados. As funcionalidades ativadas em sua conta não estarão disponíveis após o logout bem-sucedido. Por favor, proceda com cautela!",
    destroy_success: "Cancelar conta com sucesso!",
    pwd_wrong: "Senha de usuário incorreta!",
    have_sub:
      "Não é possível cancelar uma assinatura com estado de renovação normal. Por favor, pause a assinatura primeiro!",
  },
  subscription: {
    stop_text: "Você pausou o plano de assinatura de membro para {plan}.",
    cancel_text: "A assinatura do seu {plan} foi cancelada.",
    canceling:
      "Seu plano de assinatura premium para {plan} está sendo cancelado no momento.",
    renew: "Retomar assinatura",
    renew_sucess: "Retomada da assinatura com sucesso!",
    expire_date: "Data de expiração",
    status_text: "Status da assinatura",
    leave: "Sair",
    purchase_date: "Horário de compra",
    end_date: "Próximo pagamento",
    price: "Preço",
    num: "Número de dispositivos suportados",
    account_status: "Estado da conta",
    no_subscription: "Atualmente não possui assinaturas, ",
    recommend: "talvez você tenha interesse no seguinte conteúdo",
    learn_more: "Saiba mais",
    free_download: "Download gratuito",
    trial_limit: "Limitações de teste",
    blocked_on: "Bloqueado em",
    trial_start: "Horário de início do teste",
    start_subscription: "Iniciar assinatura",
    is_trial: "Você está atualmente em estado de teste",
    manage_btn: "Gerenciar assinatura",
    change_payment: "Alterar detalhes de pagamento",
    payment_detail: "Detalhes de pagamento",
    leave_tip: "Tem certeza de que deseja sair do plano familiar?",
    leave_success: "Você saiu com sucesso do plano familiar!",
    singular_device_num:
      "{num1} dispositivos {type} disponíveis, {num2} ativado",
    plural_device_num:
      "{num1} dispositivos {type} disponíveis, {num2} ativados",
    see_detail: "Ver detalhes",
    active: "Ativo",
    pause: "Pausa",
    overdue: "(Atrasado)",
    update_payment_success:
      "Alteração da forma de pagamento realizada com sucesso",
    being_processed: "Em processamento",
  },
  subaccount: {
    invite: "Convidar pessoas para se juntar",
    send_invite: "Enviar convite",
    manage_title: "Gerenciar membros da família",
    remove: "Remover",
    send_success:
      "Você enviou com sucesso um e-mail de convite, aguardando confirmação da outra parte!",
    remove_user: "Tem certeza de que deseja remover o usuário {name}?",
    all_product: "Todos os produtos",
  },
  billing: {
    excl_tax: "(excl. imposto sobre vendas)",
    view_receipt: "Ver recibo",
    all_time: "Todo o tempo",
    refunded: "Reembolsado",
  },
  manage_devices: {
    reset_all_code: "Redefinir todos os dispositivos",
    reset_a_code: "Redefinir o dispositivo atual",
    all_reset_success: "Você redefiniu todos os dispositivos com sucesso!",
    a_reset_success: "Você redefiniu {name} com sucesso!",
    num_tip:
      "Só há {num} oportunidade restante de reinicialização para este ano.",
    a_warning_text: "Tem certeza de que deseja redefinir {name}?",
    all_warning_text:
      "Tem certeza de que deseja redefinir todos os dispositivos?",
    device_name: "Nome do dispositivo",
    system: "Sistema",
    reg_time: "Hora do registro",
    using_device: "Você está usando {num1} de {num2} dispositivos",
    all: "Todos",
    residue_num: "Tentativas de reinicialização restantes",
    reset_history: "Tempo de histórico de reinicialização",
    reset_fail_9:
      "Reinicialização falhou, não há dados correspondentes para reiniciar",
    reset_fail_10:
      "Reset falhou, excedeu o limite. Se tiver alguma dúvida, entre em contato com o suporte.",
  },
  contact: {
    support_center: "Centro de suporte",
    support_tip: "Descubra o que você precisa aqui",
    feedback: "Feedback",
    submit: "Enviar",
    feedback_fail:
      "Falha ao enviar o feedback, por favor, tente novamente mais tarde!",
    feedback_success: "Obrigado pelo seu feedback!",
    enter_content: "Por favor, insira o conteúdo do feedback",
    content_placeholder: "Por favor, deixe suas sugestões ou perguntas aqui.",
    select: "Selecionar",
    select_software: "Selecione o software",
  },
  dm_home: {
    logout: "Sair",
  },
  dm_login: {
    login_desc: "Bem-vindo de volta, por favor faça login na sua conta.",
    captcha_pl: "Por favor, insira o código de verificação",
    or: "ou",
    reg: "Registrar",
    enter_tip: "Por favor, insira seu {fieldName}.",
    enter_email: "Por favor, insira seu email",
    enter_pwd: "Por favor, insira sua senha",
    captcha_limit: "O comprimento do código de verificação é 4",
    login_expired:
      "Para proteger a segurança da sua conta, é necessário inserir a senha novamente.",
    account_pwd_err: "Erro de conta de login ou de senha!",
    join_free_download:
      "Junte-se a {name} hoje e desbloqueie <span>downloads gratuitos</span>",
    new_users_only: "Somente novos usuários",
    bset_solution:
      "Melhores soluções para baixar vídeos e músicas em streaming",
  },
  dm_reg: {
    create: "Criar conta",
    fill_form: "Preencha o formulário abaixo para criar uma nova conta.",
  },
  dm_reset_pwd: {
    reset: "Recuperar sua senha",
    reset_pwd: "Redefinir senha",
    reset_tip:
      "Por favor, insira seu endereço de e-mail, e enviaremos instruções sobre como redefinir sua senha.",
    back_login: "Voltar para o login",
    email_error: "O e-mail não existe",
    reset_success: "Você redefiniu sua senha com sucesso.",
    email_had_sent:
      "O email de redefinição de senha foi enviado para a caixa de correio, por favor, redefina sua senha!",
    no_sign_up:
      "Ainda não existe uma conta para este endereço de e-mail, por favor, cadastre uma conta.",
  },
  manageSubscription: {
    cancel_sub: "Pausar assinatura",
    tip: "Se você pausar a assinatura agora, ainda pode usar {name} até o final do ciclo de faturamento atual.",
    cancel_success: "Pausa na assinatura realizada com sucesso.",
    cancel_text:
      "Você pausou com sucesso o plano de assinatura de membro para {productName}. No entanto, você ainda pode continuar usando {productName} até o último dia de sua assinatura.",
    are_you_sure: "Tem certeza de que deseja pausar a assinatura?",
    request_success: `Sua solicitação foi enviada com sucesso; nosso centro de atendimento ao cliente a processará em até 48 horas. Por favor, verifique seu e-mail de {officialEmail}.`,
    title: "Gerenciar assinatura",
    desc: "Seu plano atual é:",
    change_methods: "Alterar método de assinatura",
    choose_system_tip:
      "Por favor, selecione o sistema de dispositivo que você precisa",
    choose_num_tip:
      "Por favor, selecione o número de dispositivos que você precisa",
    enter_num: "Por favor, digite o número",
    click_enter: "Clique para inserir",
    confirm: "Confirmar",
    cancel: "Cancelar",
    choose_card_tip: "Por favor, selecione o número do cartão de pagamento",
    add_card: "Adicionar número do cartão",
    choose_way_tip:
      "Por favor, selecione o método de assinatura que você precisa",
    discounts_tip: "Você recebeu um desconto",
    total: "Total:",
    excl_tax: "(excl. imposto sobre vendas)",
    be_counting: "Calculando o preço",
    end_tip: "Sua data de vencimento é:",
    thank_text:
      "Mais uma vez, obrigado do fundo do coração por seu contínuo apoio. Estamos ansiosos para sua próxima visita!",
    back: "Voltar à assinatura",
    update_success: "Alteração da assinatura bem-sucedida",
    pay_btn: "Pagar agora",
    monthy: "Mensal",
    annual: "Anual",
    life_time: "Vitalício",
    more: "Mais",
    pay_tip:
      "Alterar método de pagamento: A plataforma deduzirá 0,01 do novo cartão, e após a dedução bem-sucedida, ele pode ser vinculado. Posteriormente, o valor será reembolsado para o método de pagamento original.",
    is_add: "Adicionar novo cartão",
    is_set: "Configurar novo cartão",
  },
  receipt: {
    title: "Recibo para {name}",
    back: "Voltar",
    receipt_text: "Recibo",
    amount_paid: "Valor pago",
    date: "Data do recibo",
    pay_method: "Método de pagamento",
    discount: "Desconto",
    discount_tip: "Os itens listados estão incluídos no preço",
    contact_tip:
      "Se precisar de ajuda com {brand}, por favor, entre em contato conosco",
    thank: "Obrigado",
    item: "Itens e Descrição",
    qty: "Quantidade",
    price: "Preço",
    amount: "Valor Bruto Retirado",
    subtotal: "Subtotal",
    total: "TOTAL",
  },
  order_complete: {
    title: "Pedido concluído!",
    desc: "Obrigado pela sua compra! Em breve, um e-mail de entrega será enviado para o e-mail do cliente.",
    order_id: "Seu ID do pedido",
    price: "Total do pedido",
    name: "Nome do produto",
    delivery: "Entrega",
    electronic: "Eletrônico",
    unit_price: "Preço unitário",
    quantity: "Quantidade",
    final: "Preço com Desconto",
    detail: "Detalhes do pedido",
    license_email: "E-mail licenciado",
    license_tip: "A chave de licença será incluída no e-mail de entrega.",
    contact_text:
      "Se não recebeu o e-mail de entrega, por favor, entre em contato com o suporte ao cliente:",
  },
  popup: {
    ok: "Ok",
    cancel: "Cancelar",
    confirm: "Confirmar",
    options: ["Problemas de software", "Preço", "Outros"],
  },
  add_new_card: {
    please_fill: "Por favor, complete as informações",
  },
  spotify: {
    "download-spotify-to-mp3": "Baixe o Spotify para mp3",
    upgrade: "Atualize",
    "enter-a-spotify-url-of-any-track-to-download":
      "Digite uma URL do Spotify de qualquer faixa para baixar",
    cancel: "Cancelar",
    search: "Pesquisar",
    get_started: "Copie uma URL para começar",
    please_wait: "Aguarde enquanto seu arquivo está sendo baixado",
    upgrade_now: "Atualize agora para downloads ilimitados",
    oops: "Ops! Algo deu errado.",
    unexpected_error:
      "Ocorreu um erro inesperado. Verifique sua conexão e tente novamente.",
    retry: "Tentar novamente",
    have_you_made_payment: "Você fez o pagamento?",
    click_yes_below:
      "Clique em 'Sim' abaixo para atualizar seus benefícios de assinatura.",
    no: "Não",
    yes: "Sim",
    you_may_give_an_incorrect_url_change_a_url_or_try_again:
      "Você pode fornecer uma URL incorreta. Altere uma URL ou tente novamente.",
    max_count: "Máximo de {pageSize} músicas por vez",
    clear_all: "Limpar tudo",
    total: "Total",
    selected: "Selecionado",
    title: "Título",
    album: "Álbum",
    duration: "Duração",
    get_premium: "Obtenha Premium",
    you_have_songs_left:
      "Você tem <span>{num}</span> músicas restantes. Atualize agora para downloads ilimitados.",
    free_trial: "Teste grátis",
    premium: "Premium",
    desktop_app: "Aplicativo para desktop",
    limited_features: "Recursos limitados",
    current_plan: "Plano atual",
    monthly: "Pague mensalmente",
    annually: "Pague anualmente",
    lifetime: "Pague uma vez",
    mon: "seg",
    lifelong: "vitalício",
    try_lt_free: "Experimente grátis",
    audio_quality: "Qualidade de áudio",
    normal: "Normal",
    download_format: "Formato de download",
    download_limit: "Limite de download",
    two_songs: "2 músicas",
    unlimited: "Ilimitado",
  },
  advertise: {
    desc: "Baixe músicas, álbuns, playlists, podcasts em uma velocidade 35X mais rápida.",
    learn_more: "Saiba mais",
  },
  celebrate: "Você está pronto para downloads gratuitos. Comece agora mesmo!",
  toolkit: "Kit de ferramentas",
  my_account: "Minha conta",
};
