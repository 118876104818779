import { render, staticRenderFns } from "./customForm.vue?vue&type=template&id=29768b39&scoped=true"
import script from "./customForm.vue?vue&type=script&lang=js"
export * from "./customForm.vue?vue&type=script&lang=js"
import style0 from "./customForm.vue?vue&type=style&index=0&id=29768b39&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "29768b39",
  null
  
)

export default component.exports