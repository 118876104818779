<template>
  <div class="login">
    <p class="title">{{ $t("login.login") }}</p>
    <p class="text">{{ $t("dm_login.login_desc") }}</p>
    <custom-form
      ref="form"
      :source="'Login'"
      :formInfo="loginInfo"
      :bottomLink="{
        href: '/register',
        text: $t('dm_login.reg'),
      }"
      :confirmText="$t('login.login')"
      :isLogin="true"
    >
      <template v-slot:beforeBtn>
        <el-form-item>
          <div class="pw-operate">
            <el-checkbox
              el-checkbox
              v-model="checked"
              :label="$t('login.free_login')"
            ></el-checkbox>
            <span class="p-a" @click="handleForgetPwd">
              {{ $t("login.forgot_pwd") }}
            </span>
          </div>
        </el-form-item>
      </template>
      <template v-slot:beforeOr>
        <div class="third-party-login" v-if="vendorInfo.hasGoogleLogin">
          <google-login :page="'login'" :fp="fp"></google-login>
        </div>
      </template>
    </custom-form>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { login } from "@/api/login.js";
import customForm from "../customForm.vue";
import googleLogin from "../googleLogin.vue";

export default {
  name: "loginComponent",
  components: { customForm, googleLogin },
  data() {
    return {
      loginInfo: {
        email: {
          type: "email",
          value: process.env.VUE_APP_ACCOUNT || "",
        },
        password: {
          type: "password",
          value: process.env.VUE_APP_PASSWORD || "",
        },
      },
      free_login: 0,
      checked: false,
      form: null,
    };
  },
  props: {
    fp: {
      type: String,
    },
  },
  computed: {
    ...mapGetters(["vendorInfo"]),
  },
  methods: {
    handleConfirm() {
      const info = this.form.getReqInfo();
      this.$eventTracking({
        event: "ClickLogin",
        email: info.email,
        location: "LoginWindow",
      });
      info.free_login = this.checked ? 1 : 0;
      login({ ...info, finger_print: this.fp, fp_version: window.fpVersion })
        .then(response => {
          this.$eventTracking({
            event: "LoginResult",
            email: info.email,
            result: "Success",
          });
          this.loginSuccess(response.data);
        })
        .catch(error => {
          this.form.isLoading = false;
          let errMsg = error.response?.data?.message || "error";
          switch (errMsg) {
            case "Login account or password error":
              this.form.errorTip = this.$t("dm_login.account_pwd_err");
              break;
            case "User mailbox does not exist!":
              this.form.errorTip = this.$t("dm_reset_pwd.email_error");
              break;
            default:
              this.form.errorTip = errMsg;
          }
          this.form.judegeCaptcha(errMsg);
          this.$eventTracking({
            event: "LoginResult",
            email: info.email,
            result: "Failed",
            reason: errMsg,
          });
        });
    },
    loginSuccess(data) {
      this.$cookieManager.setCookie(
        "_token",
        data.access_token,
        data.expires_at,
        1
      );
      this.form.isLoading = false;
      this.$store.commit("dmCommon/changeLoginStatus", true);
      this.$navigateTo({ text: "登录成功" }, "/personal");
      this.$store.commit("user/setUserInfo", null);
      if (sessionStorage.getItem("regInfo"))
        sessionStorage.removeItem("regInfo");
    },
    opCaptcha(n) {
      n == 0
        ? this.$set(this.loginInfo, "_captcha", {
            type: "captcha",
            value: "",
          })
        : delete this.loginInfo._captcha;
    },
    handleForgetPwd(event) {
      this.$eventTracking({
        event: "ClickForgetPassword",
        location: "LoginWindow",
      });
      this.$navigateTo(event, "/reset-password");
    },
  },
  watch: {
    checked(n) {
      this.$eventTracking({
        event: n ? "ClickAutoLogin" : "CancelAutoLogin",
        location: "LoginWindow",
      });
    },
  },
  mounted() {
    if (sessionStorage.getItem("regInfo")) {
      const { email, password } = JSON.parse(sessionStorage.getItem("regInfo"));
      this.loginInfo.email.value = email;
      this.loginInfo.password.value = password;
    }

    this.form = this.$refs.form;
  },
};
</script>
